import React from 'react'
import { SidebarContainer, Icon, CloseIcon,SidebarWrapper,SidebarMenu,SidebarLink,SideBtnWrap,SidebarRoute } from './SidebarElements'


const Sidebar = ({isOpen,toggle}) => {
    return (
       <SidebarContainer isOpen = {isOpen} onClick={toggle}>
           <Icon onClick={toggle}>
               <CloseIcon />
           </Icon>
           <SidebarWrapper>
               <SidebarMenu>
                   <SidebarLink to = "signup" onClick={toggle}>
                        About
                   </SidebarLink>
                   <SidebarLink to = "discover" onClick={toggle}>
                        Discover
                   </SidebarLink>
                   <SidebarLink to = "services" onClick={toggle}>
                        Services
                   </SidebarLink>
                   <SidebarLink to = "about" onClick={toggle}>
                        Quality
                   </SidebarLink>

               </SidebarMenu>
               
           </SidebarWrapper>
       </SidebarContainer>

    )
}

export default Sidebar
